import { Directive, TemplateRef, inject } from "@angular/core";

@Directive({
  selector: "[accordionHeader]",
  standalone: true,
})
export class AccordionHeader {
  templateRef = inject<TemplateRef<any>>(TemplateRef);

  constructor() {}
}
